import router from 'next/router';
import { useDispatch } from 'react-redux';
import { getRoute, PATH } from '@/utils/routes';
import { getNavigationPagesList, toggleRfqFormModal } from '@/redux/actions';
import { useEffect, useState, useRef, useCallback } from 'react';
import ReactHamburger from '@/components/Common/ReactHamburger';
import LoginComponent from '@/components/LoginAndDropdownComponent';
import { deviceWidth } from '@/utils/deviceWidth';
import { IS_DESKTOP, SSR_DESKTOP, SSR_MOBILE } from '@/utils/checkClientDeviceType';
import { getContrastingColor } from '@/utils/getContrastingColor';
import { invertAngleRightIconColor } from '@/utils/invertAngleRightIconColor';
import { showMegaMenu } from '@/utils/showMegaMenu';
import { getStoreType } from '@/utils/getStoreType';
import { CommonHeaderIconsMobile } from '../CommonHeaderIcons';
// import CollectionHeaderDropdown from '../CollectionHeaderDropdown';
import { ShoppingBag } from '@/assets/svgExports/ShoppingBag';
import { LoginUserIcon } from '@/assets/svgExports/LoginUserIcon';
import { HeaderInfoIcon } from '@/assets/svgExports/HeaderInfoIcon';
import { HeaderHome } from '@/assets/svgExports/HeaderHome';
import { StoreNameComp } from '../StoreNameComponent';
import ShopComponent from '../ShopComponent';
import { SearchIcon } from '@/assets/svgExports/SearchIcon';
import CommonNavbarText from '../CommonNavbarText/CommonNavbarText';
import useSaveHeaderHeight from '../../../../hooks/useSaveHeaderHeight';
import { STORE_CUSTOMIZATION } from '@/utils/constants';
import { useHeaderData } from '../HeaderUtils/useHeaderData';
import { IS_SERVER } from '@/utils/checkRenderEnv';
import MegaMenuDesktopView from '@/components/MegaMenu/MegaMenuDesktopView';
import { MenuDrawerMobile } from '../../Drawer/MenuDrawerMobile';
import { AccordionCloseIcon, AccordionOpenIcon } from '@/components/MegaMenu/svgIcons';
import { useSSRSelector } from '@/redux/ssrStore';

const Header11 = (props) => {
  const ref = useRef();
  const subNavListRef = useRef();
  const dispatch = useDispatch();
  const [open, setOpen] = useState({
    openShop: false,
    openMore: false,
    openDropdown: false,
  });
  const [openMegaMenu, setOpenMegaMenu] = useState({
    desktop: false,
    mobile: false,
  });
  const [, setOpenCollections] = useState(false);
  const headerBgColor = props?.config?.color_scheme?.bg_color || '#FFFFFF';
  const { storeData, items, collections, user, navItems } = useHeaderData(props);
  const { headerHeight } = useSSRSelector((state) => ({
    headerHeight: state.commonReducer?.headerHeight,
  }));

  const isDesktop = deviceWidth > 786;
  const showHeaderIcon = !(isDesktop || IS_SERVER);
  const headerRef = useRef();
  // const isShopDropDownEnable = useIsShopDropDownEnable();

  useSaveHeaderHeight(headerRef);

  useEffect(() => {
    if (!open.openDropdown) setOpenCollections(false);
  }, [open.openDropdown]);

  useEffect(() => {
    // useSSE, ssrTBR To be removed, This will fix the navigation flicker after load
    if (storeData?.store_id && !navItems?.length) {
      dispatch(
        getNavigationPagesList({
          storeId: storeData?.store_id,
          isPublished: true,
        })
      );
    }
  }, [storeData?.store_id]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleClickOutside = (e) => {
    if (isDesktop) {
      if (ref.current && ref.current.contains(e.target)) {
        return;
      }
      setOpen((state) => ({ ...state, openShop: false, openMore: false }));
    }
  };

  const clickForRoute = (route) => {
    router.push(getRoute(route, storeData?.store_info?.domain));
  };

  const handleCartClick = () => {
    if (getStoreType(storeData) === STORE_CUSTOMIZATION.B2B) {
      dispatch(toggleRfqFormModal());
      return;
    }
    clickForRoute(PATH.CART);
  };

  const menuItemClick = (nav) => {
    if (openMegaMenu?.desktop || openMegaMenu?.mobile) {
      setOpenMegaMenu(() => ({
        desktop: false,
        mobile: false,
      }));
    }

    if (nav?.page_type === 'blank_page' && nav?.is_external_url) {
      window.open(nav.slug_url, '_blank');
      return;
    }

    if (nav?.slug_url === 'shop') {
      if (document.querySelector('#CollectionCategoryProductWidget')) {
        document.querySelector('#CollectionCategoryProductWidget').scrollIntoView();
      } else {
        router.push(getRoute(PATH.PRODUCT, storeData?.store_info?.domain));
      }
    } else if (nav?.slug_url === 'subNavList') {
      setOpen((state) => ({
        ...state,
        openMore: !open.openMore,
      }));
    } else {
      if (nav?.is_editable) {
        router.push(
          getRoute(PATH.additionalPage(nav.slug_url), storeData?.store_info?.domain)
        );
      } else {
        router.push(getRoute(nav.slug_url, storeData?.store_info?.domain));
      }
    }
  };

  const toggleDropdown = () => {
    setOpen((state) => ({
      ...state,
      openDropdown: !state.openDropdown,
    }));
  };

  const navSubList = (nav) => {
    return (
      <div
        className="cfe-dropdown-nav"
        ref={ref}
        style={{
          top: headerHeight - (subNavListRef.current?.offsetTop || 0),
        }}
      >
        <div className="cfe-dropdown-nav__items">
          {nav.map((n) => (
            <p
              className="cfe-dropdown-nav__item pv1"
              key={n.page_id}
              onClick={() => menuItemClick(n)}
            >
              {n.page_name}
            </p>
          ))}
        </div>
      </div>
    );
  };

  const closeShopDropdown = useCallback(
    () => setOpen((state) => ({ ...state, openShop: false, openDropdown: false })),
    []
  );

  const handleShopClick = (page, dropdownNav) => {
    if (showMegaMenu()) {
      IS_DESKTOP
        ? setOpenMegaMenu(() => ({
            mobile: false,
            desktop: !openMegaMenu.desktop,
          }))
        : setOpenMegaMenu(() => ({
            desktop: false,
            mobile: !openMegaMenu.mobile,
          }));
    } else {
      if (collections?.length) {
        if (dropdownNav) {
          setOpenCollections(true);
          setOpen((state) => ({ ...state, openShop: !state.openShop }));
        } else {
          menuItemClick(page);
        }
      } else {
        !dropdownNav && menuItemClick(page);
        open.openShop
          ? setOpen((state) => ({ ...state, openShop: false }))
          : setOpen((state) => ({ ...state, openShop: true, openMore: false }));
      }
    }
  };

  const renderNavType = (page, dropdownNav = false) => {
    switch (page.slug_url) {
      case '':
        return (
          <li
            key={page.page_id}
            className={`menu-item relative`}
            onClick={() => menuItemClick(page)}
          >
            <div className="flex items-center justify-between">
              <div className="flex items-center" style={{ flex: '1 1 auto' }}>
                {showHeaderIcon ? (
                  <div className="mr3">
                    <HeaderHome
                      color={
                        storeData?.theme?.colors?.primary_color !== '#ffffff'
                          ? '#000000'
                          : '#ffffff'
                      }
                    />
                  </div>
                ) : null}
                <CommonNavbarText bgColor={headerBgColor} page={page} />
              </div>
            </div>
          </li>
        );
      case 'shop':
        return (
          <ShopComponent
            ref={ref}
            page={page}
            closeShopDropdown={closeShopDropdown}
            bgColor={headerBgColor}
            showMegaMenuDesktop={openMegaMenu.desktop}
            setOpenMegaMenu={setOpenMegaMenu}
            // open={open}
            // dropdownNav={isShopDropDownEnable}
            handleShopClick={handleShopClick}
          />
        );
      case 'subNavList':
        return (
          <li key={page.page_id} className={`menu-item relative`} ref={subNavListRef}>
            <div
              className="flex items-center justify-between"
              onClick={() => menuItemClick(page)}
            >
              <div className="flex items-center" style={{ flex: '1 1 auto' }}>
                <CommonNavbarText bgColor={headerBgColor} page={{ page_name: 'More' }} />
              </div>
              {page?.subNavs?.length > 0 && (
                <div className=" tw-ml-[2px]">
                  {open.openMore ? (
                    <AccordionCloseIcon
                      height={12.5}
                      width={12.5}
                      // secondaryColor={invertAngleRightIconColor(headerBgColor)}
                    />
                  ) : (
                    <AccordionOpenIcon
                      height={12.5}
                      width={12.5}
                      // secondaryColor={invertAngleRightIconColor(headerBgColor)}
                    />
                  )}
                </div>
              )}
            </div>
            {open.openMore && navSubList(page.subNavs)}
          </li>
        );
      case 'about-us':
        return (
          <li
            key={page.page_id}
            className={`menu-item relative`}
            onClick={() => menuItemClick(page)}
          >
            <div className="flex items-center justify-between">
              <div className="flex items-center" style={{ flex: '1 1 auto' }}>
                {showHeaderIcon ? (
                  <div className="mr3">
                    <HeaderInfoIcon
                      color={
                        storeData?.theme?.colors?.primary_color !== '#ffffff'
                          ? '#000000'
                          : '#ffffff'
                      }
                    />
                  </div>
                ) : null}
                <CommonNavbarText bgColor={headerBgColor} page={page} />
              </div>
            </div>
          </li>
        );
      default:
        return (
          <li
            key={page.page_id}
            className={`menu-item`}
            onClick={() => menuItemClick(page)}
          >
            <CommonNavbarText bgColor={headerBgColor} page={page} />
          </li>
        );
    }
  };

  const searchBar = () => (
    <div
      className="search-comp pointer"
      onClick={() => clickForRoute(PATH.SEARCH)}
      style={{
        borderBottom: `0.4px solid ${getContrastingColor(headerBgColor)}`,
      }}
    >
      <SearchIcon
        className="mr3"
        width="20"
        height="20"
        color={getContrastingColor(headerBgColor)}
      />
      <span className="ttu">Search</span>
    </div>
  );

  const leftSection = () => (
    <>
      <div className="visible-desktop-flex items-center" style={{ flex: '1 1 auto' }}>
        <span>
          <StoreNameComp showLogo />
        </span>
        <div>{navigationItems()}</div>
      </div>
      <div className="visible-mobile-flex">
        <ReactHamburger
          open={open.openDropdown}
          setOpen={toggleDropdown}
          theme={getContrastingColor(headerBgColor)}
        />
      </div>
    </>
  );

  const navigationItems = () => {
    return (
      navItems?.length > 0 && (
        <ul className="menu-list">{navItems?.map((nav) => renderNavType(nav, false))}</ul>
      )
    );
  };

  const rightSection = () => {
    return (
      <div style={{ flex: '1' }}>
        {SSR_DESKTOP && (
          <section className="flex items-center justify-end icon-set visible-desktop-flex">
            {searchBar()}
            <div
              className="right-section-component flex"
              style={{
                paddingRight: 24,
                width: '108px',
              }}
            >
              <LoginComponent headerBgColor={headerBgColor}>
                {!user?.isLogin ? <p className="header-text">Login</p> : null}
                <LoginUserIcon
                  width="23"
                  height="23"
                  color={getContrastingColor(headerBgColor)}
                />
                {user?.isLogin ? (
                  <img
                    alt="dropdown button"
                    src="/assets/images/down-arrow-thin.svg"
                    className={`ml2 arrow-icon ${invertAngleRightIconColor(
                      headerBgColor
                    )}`}
                  />
                ) : (
                  ''
                )}
              </LoginComponent>
            </div>
            <div className="right-section-component flex" onClick={handleCartClick}>
              <p className="header-text">Cart</p>
              <ShoppingBag
                width="25"
                height="25"
                color={getContrastingColor(headerBgColor)}
              />
              {items?.length > 0 && (
                <span
                  className={`cart-items absolute z-2 ${invertAngleRightIconColor(
                    headerBgColor
                  )}`}
                >
                  {items.length}
                </span>
              )}
            </div>
          </section>
        )}
        {SSR_MOBILE && (
          <CommonHeaderIconsMobile
            className="visible-mobile-flex"
            color={getContrastingColor(headerBgColor)}
            headerName="header11"
          />
        )}
      </div>
    );
  };

  return (
    <header
      ref={headerRef}
      className="header11 collection-header search-comp"
      style={{
        background: headerBgColor,
        color: getContrastingColor(headerBgColor),
      }}
    >
      <div className="header11__component">
        {leftSection()}
        {rightSection()}
      </div>
      {openMegaMenu.desktop && (
        <MegaMenuDesktopView
          open={openMegaMenu.desktop}
          onClose={() => setOpenMegaMenu({ desktop: false, mobile: false })}
        />
      )}
      <MenuDrawerMobile isVisible={open.openDropdown} closeDrawer={toggleDropdown} />
      {/* {open.openDropdown && (
        <CollectionHeaderDropdown
          renderNavType={renderNavType}
          navItemsState={navItems}
          openCollections={openCollections}
          goBack={() => setOpenCollections(false)}
          collectionListType={props?.config?.name || null}
          toggleDropdown={toggleDropdown}
        />
      )} */}
    </header>
  );
};

export default Header11;
